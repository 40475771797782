import { uploadKocPhotoUrl } from '@/Axios/api/Media';
import { customAxios } from '@/Axios/index';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { updatePostInfoImage } from '@/Redux/slices/ModalSlice';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import React, { useState } from 'react';

const InfoImageModal = ({
  postUuid,
  visible,
  setVisible,
}: {
  postUuid: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { info } = useAppSelector((state) => state.modal.value.postDetail);

  const [fileList, setFileList] = useState<UploadFile[]>(
    (info && info.imageId && info.imageUrl ? [info] : []).map((data) => ({
      name: 'original-info-iamge',
      uid: data.imageId,
      url: data.imageUrl,
    })),
  );
  const [uploadFileLoading, setUploadFileLoading] = useState(false);

  const bearerToken = customAxios.defaults.headers.common.Authorization;
  const uploadPhotoUrl = customAxios.defaults.baseURL + uploadKocPhotoUrl;

  const handleChange: UploadProps['onChange'] = (param: UploadChangeParam<UploadFile>) => {
    if (param.file.status === 'uploading') {
      setUploadFileLoading(true);
      setFileList([param.file]);
    } else if (param.file.status === 'done') {
      setUploadFileLoading(false);
      setFileList([param.file]);
    } else if (param.file.status === 'removed') {
      setUploadFileLoading(false);
      setFileList([]);
    }
  };

  const uploadButton = (
    <div>
      {uploadFileLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onConfirm = () => {
    const request = {
      uuid: postUuid,
      postInfoImageData: {
        imageId: fileList[0]?.response[0].imageId,
        imageUrl: fileList[0]?.response[0].imageUrl,
      },
    };
    dispatch(updatePostInfoImage(request));
    setVisible(false);
  };

  const onCancel = () => {
    setUploadFileLoading(false);
    setFileList(
      (info && info.imageId && info.imageUrl ? [info] : []).map((data) => ({
        name: 'original-info-iamge',
        uid: data.imageId,
        url: data.imageUrl,
      })),
    );
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      title="VOD Additional Information"
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
      }}
      okText="Save"
      okButtonProps={{ disabled: uploadFileLoading }}
      cancelButtonProps={{ disabled: uploadFileLoading }}
      closable={false}
      maskClosable={false}
    >
      <Upload
        listType="picture-card"
        onChange={handleChange}
        action={uploadPhotoUrl}
        headers={{
          authorization: bearerToken,
        }}
        name="images"
        className="info-image-upload"
        accept="image/*"
        fileList={fileList}
        disabled={uploadFileLoading}
        maxCount={1}
      >
        {uploadButton}
      </Upload>
    </Modal>
  );
};

export default InfoImageModal;
