import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PaginationData,
  PostDetailData,
  ReasonData,
  ReportRecordData,
  UpdatePostInfoImageRequest,
  UserReportRecordData,
} from '@/Interfaces/I_modal';

export const ModalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: {
      postDetail: {} as PostDetailData,
      reportRecord: {
        data: [] as ReportRecordData[],
        pagination: {} as PaginationData,
      },
      userReportRecord: {
        data: [] as UserReportRecordData[],
        pagination: {} as PaginationData,
      },
      reasons: [] as ReasonData[],
    },
    loading: false,
    message: '',
  },
  reducers: {
    postReport: (state, action) => {
      const assignState = state;
      assignState.message = '';
    },
    setPostReport: (state, action) => {
      const assignState = state;
      assignState.message = action.payload;
    },
    getPostDetail: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPostDetail: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, postDetail: { ...action.payload.data } };
      assignState.loading = false;
    },
    getReportRecord: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setReportRecord: (state, action) => {
      const assignState = state;
      assignState.value = {
        ...assignState.value,
        reportRecord: action.payload.data,
      };
      assignState.loading = false;
    },
    appendReportRecord: (state, action) => {
      const assignState = state;
      assignState.value = {
        ...assignState.value,
        reportRecord: {
          data: [...assignState.value.reportRecord.data, ...action.payload.data.data],
          pagination: action.payload.data.pagination,
        },
      };
      assignState.loading = false;
    },
    getUserReportRecord: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setUserReportRecord: (state, action) => {
      const assignState = state;
      assignState.value = {
        ...assignState.value,
        userReportRecord: action.payload.data,
      };
      assignState.loading = false;
    },
    appendUserReportRecord: (state, action) => {
      const assignState = state;
      assignState.value = {
        ...assignState.value,
        userReportRecord: {
          data: [...assignState.value.userReportRecord.data, ...action.payload.data.data],
          pagination: action.payload.data.pagination,
        },
      };
      assignState.loading = false;
    },
    getReasons: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setReasons: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, reasons: action.payload.data };
      assignState.loading = false;
    },
    updatePostSku: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    updatePostAnnouncement: (state, _action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPostAnnouncement: (state, action) => {
      const assignState = state;
      assignState.loading = false;
      assignState.value.postDetail.announcement = action.payload;
    },
    updatePostInfoImage: (state, _action: PayloadAction<UpdatePostInfoImageRequest>) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPostInfoImage: (state, action) => {
      const assignState = state;
      assignState.loading = false;
      assignState.value.postDetail.info = action.payload;
    },
  },
});

export const {
  getPostDetail,
  setPostDetail,
  getReportRecord,
  setReportRecord,
  getUserReportRecord,
  setUserReportRecord,
  getReasons,
  setReasons,
  postReport,
  appendReportRecord,
  appendUserReportRecord,
  setPostReport,
  updatePostSku,
  updatePostAnnouncement,
  setPostAnnouncement,
  updatePostInfoImage,
  setPostInfoImage,
} = ModalSlice.actions;

export default ModalSlice.reducer;
