/* eslint-disable camelcase */
import {
  PostReportParam,
  ReasonParam,
  ReportRecordParam,
  UserReportRecordParam,
  UpdatePostSkuParam,
  UpdatePostInfoImageRequest,
} from '@/Interfaces/I_modal';

import { customAxios } from '../index';

// hide / release / ignore post
export const postReportAPI = (data: PostReportParam) =>
  customAxios({
    url: 'hktv_koc/cms/post_report_handles',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data,
  });

// get post detail
export const fetchPostDetail = (postUuid: string) =>
  customAxios({
    url: `hktv_koc/cms/posts/${postUuid}`,
    method: 'GET',
  });

// get report record
export const fetchReportRecord = ({ post_uuid, page_no = 0, page_size = 10 }: ReportRecordParam) =>
  customAxios({
    url: `hktv_koc/cms/post_report_handles?post_uuid=${post_uuid}&page_no=${page_no}&page_ize=${page_size}`,
    method: 'GET',
  });

// get user report record
export const fetchUserReportRecord = ({
  post_uuid,
  post_report_handle_id,
  page_no = 0,
  page_size = 10,
}: UserReportRecordParam) =>
  customAxios({
    url: `hktv_koc/cms/post_reports?${
      post_uuid ? `post_uuid=${post_uuid}` : `&post_report_handle_id=${post_report_handle_id}`
    }&page_no=${page_no}&page_ize=${page_size}`,
    method: 'GET',
  });

// get reasons
export const fetchReasons = ({ reason_type }: ReasonParam) =>
  customAxios({
    url: `hktv_koc/cms/reasons?reason_type=${reason_type}`,
    method: 'GET',
  });

// update Post Sku
export const postPostSkuAPI = ({ post_uuid, ...data }: UpdatePostSkuParam) =>
  customAxios({
    url: `hktv_koc/cms/posts/sku_timestamp/${post_uuid}`,
    method: 'POST',
    data,
  });

export const patchPostInfoImage = (request: UpdatePostInfoImageRequest) =>
  customAxios({
    url: `hktv_koc/cms/posts/info/${request.uuid}`,
    method: 'PATCH',
    data: request.postInfoImageData,
  });
