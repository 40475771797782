import { createSlice } from '@reduxjs/toolkit';

import {
  CategoriesData,
  ExtraScoreData,
  PaginationData,
  PinnedLandingPost,
  PinnedExplorePost,
  PostManagementData,
} from '@/Interfaces/I_postManagement';

export const postManagementSlice = createSlice({
  name: 'postManagementSlice',
  initialState: {
    value: {
      categories: [] as CategoriesData[],
      mallCategories: [] as CategoriesData[],
      post: {
        data: [] as PostManagementData[],
        pagination: {} as PaginationData,
      },
      keywordPost: {
        data: [] as PostManagementData[],
        pagination: {} as PaginationData,
      },
      pinnedLandings: [] as PinnedLandingPost[],
      pinnedExplorePosts: [] as PinnedExplorePost[],
    },
    loading: false,
    error: '',
    source: '',
  },
  reducers: {
    getCategories: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setCategories: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, categories: action.payload.data };
      assignState.loading = false;
    },
    getPost: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, post: action.payload.data };
      assignState.loading = false;
    },
    setKeywordPost: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, keywordPost: action.payload.data };
      assignState.loading = false;
    },
    appendPost: (state, action) => {
      const assignState = state;
      assignState.value = {
        ...assignState.value,
        post: {
          data: [...assignState.value.post.data, ...action.payload.data.data],
          pagination: action.payload.data.pagination,
        },
      };
      assignState.loading = false;
    },
    patchPostData: (state, action) => {},
    patchPostDataSuccess: (state, action) => {
      const assignState = state;
      action.payload.forEach((updatedPostData: ExtraScoreData) => {
        const postIdx = assignState.value.post.data.findIndex(
          (post) => post.uuid === updatedPostData.uuid,
        );
        assignState.value.post.data[postIdx].popularityScore = updatedPostData.popularityScore;
        assignState.value.post.data[postIdx].extraScore = updatedPostData.extraScore;
        assignState.value.post.data[postIdx].pdp = updatedPostData.pdp;
        assignState.value.post.data[postIdx].landingPinned = updatedPostData.landingPinned;
        assignState.value.post.data[postIdx].explorePinned = updatedPostData.explorePinned;
        assignState.value.post.data[postIdx].announcement = updatedPostData.announcement;
      });
    },
    patchPostCategory: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    patchPostCategorySuccess: (state, action) => {
      const assignState = state;
      assignState.loading = false;
    },
    getMallCategories: (state) => {
      const assignState = state;
      assignState.loading = true;
    },
    setMallCategories: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, mallCategories: action.payload.data };
      assignState.loading = false;
    },
    getPinnedLandings: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPinnedLandings: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, pinnedLandings: action.payload.data };
      assignState.loading = false;
    },
    updatePinnedLandings: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    getPinnedExplorePosts: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setPinnedExplorePosts: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, pinnedExplorePosts: action.payload.data };
      assignState.loading = false;
    },
    updatePinnedExplorePosts: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
  },
});

export const {
  getCategories,
  setCategories,
  getPost,
  setPost,
  appendPost,
  patchPostData,
  patchPostDataSuccess,
  setKeywordPost,
  patchPostCategory,
  patchPostCategorySuccess,
  getMallCategories,
  setMallCategories,
  getPinnedLandings,
  setPinnedLandings,
  updatePinnedLandings,
  getPinnedExplorePosts,
  setPinnedExplorePosts,
  updatePinnedExplorePosts,
} = postManagementSlice.actions;

export default postManagementSlice.reducer;
